@import "~core/wishlist/helpers";

// heart icon on products
.wishlist-toggle-product {
    .wishlist-icon {
        @media screen and (min-width: 1200px) {
            &:hover {
                @include icon($product-tile-wishlist-link-icon-selected, before, $product-tile-wishlist-link-icon-size, #ba4257);
            }
        }
    }

    &:not(.move) {
        top: 0;
    }
}
