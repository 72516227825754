.page-header {
    padding-top: $page-header-padding-top;
    padding-bottom: $page-header-padding-bottom;
    @include media-breakpoint-up(md) {
        padding-top: $page-header-padding-top-md;
        padding-bottom: $page-header-padding-bottom-md;
    }
    &.blog-detail-header {
        .page-header-title {
            @include header($blog-detail-title-size);
        }
    }
    &.search-results-header {
        .page-header-title {
            @include header(2);
            font-family: $tertiary-font;
            font-style:italic;
            text-align: center;
            margin:$spacer * 3;
            line-height: normal !important; /* Fixes text clipping issue when resized to 200% for better accessibility */
            overflow: auto; /* Ensures any overflowing text remains visible */
        }
    }
}

// Don't show the page title when a slot is present
.hero-slider-container, .hero-slot {
    + .page-header {
        .page-header-title {
            display: $plp-name-header-display-with-slot;
        }
    }
}