@import "~core/components/footer";

.email-signup-form {
    .email-signup-message-inline {
        min-height: 20px;
        padding-top: 4px;
    }
    .email-signup-alert {
        margin: 0;
        border: none;
        box-shadow: none;
        font-size: 12px;
        text-align: initial;
        animation: fadeIn 200ms;

        &.alert {
            padding: 0px;

            &-success,
            &-danger {
                background: none;
                border-color: transparent;
                color: $red;
            }
        }
    }
}

footer {
    padding-top: 0;
    margin-top: 40px;
    background-color: $white-smoke;
    font-size: 18px;
    font-family: $brandon-grotesque;
    color: $black;

    .content {
        li {
            height: $footer-link-height;
            a {
                text-decoration: none;
                line-height: 3em;
                display: inline-block;
                min-width: 44px;
            }
        }
    }

    .copyright,
    .postscript {
        line-height: 16px;
    }

    #wgaca-footer-logo {
        margin-left: 0;
        width: 203px;
        height: 80px;
        img {
             width: 100%;
        }
        a:hover {
            border-bottom: none;
        }
        @include media-breakpoint-up(md) {
            margin: 0 auto;
        }
    }
    a:hover {
    	color: $black;
        border-bottom: 2px solid $black;
    }
    .footer-middle-block {
        overflow-x: hidden;
	    .sell-footer-link {
	        text-transform: uppercase;
		    font-size: 16px;
            color: $footer-copyright-color;
	        padding-top: 32px;
	        a {
                color: $black;
	            text-decoration: underline;
                border-bottom: 0;
                &:hover {
                    color: $brand-tertiary;
                }
	        }
            @include media-breakpoint-up(md) {
                padding-top: 12px;
            }
	    }
    }

    @include media-breakpoint-down(md) {
	    .postscript {
	    	margin-top: 5px;
	    	text-transform: capitalize;
	    	order: 3;
	    	a {
	    		color: $black;
	    	}
	    }
	    .right-block {
	    	padding-left: 0;
	    }
    }

    .footer-disclaimer {
	    margin-top: 20px;
        color: $footer-copyright-color;
        font-size: 16px;
        padding: 0 16px;
        margin-bottom: 20px;
        @include media-breakpoint-up(md) {
            padding: 0;
            margin-bottom: 0;
        }
	}

    #subscription-status{
        font-size: 12px;
        margin-top: 4px;
    }

    .back-to-top {
        z-index: 1;
        position: fixed;
        right: 15px;
        bottom: 80px;
        display: none;
        .fa-stack {
            width: 44px;
            height: 44px;
            line-height: 44px;
        }
        .fa-circle {
            background-color: $white;
            box-shadow: 0 2px 10px rgba(34, 34, 34, 0.2);
            border-radius: inherit;
        }
        .fa-arrow-up:before {
            color: $black;
            font-size: 24px;
        }
    }
}

.footer-item {
    &.collapsible-sm {
        .content,
        .title {
            background-color: unset;
        }
        @include media-breakpoint-up(md) {
            .title {
                padding-bottom: 0;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .sell-to-us {
            order: 2;
        }
        .footer-middle-block,
        .footer-bottom-block {
            text-align: center;
        }
    }
}

.container {
    .wgaca-updates-sign-up-area {
        padding-bottom: 24px;
        padding-top: 16px;
        margin-bottom: 48px;
        border-bottom: 1px solid $light-gray;
        .sign-up-lbl {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
        }
        .input-area {
            display: flex;
            margin-top: 8px;
            padding-left: 0;
            .form-control {
                margin-right: 20px;
                max-width: 520px;
            }
        }
    }
    .right-block {
        .social-back-to-top {
            padding-right: 25px;
            .social {
                text-align:left !important;
                .social-links {
                    li {
                        &:first-child {
                            margin-left: 0;
                            @include media-breakpoint-up(md) {
                                margin-left: 7px;
                            }
                        }
                    }
                }
                @include media-breakpoint-up(md) {
                    text-align:right !important;
                }
            }
        }
    }
}
.footer-bottom-block {
    padding: 0;
    .inner-wrapper {
        border-top: 1px solid #7d8080;
        width: 100%;
        display: flex;
        padding-top: 16px;
        padding-bottom: 100px;
        @include media-breakpoint-up(md) {
            padding-bottom: 60px;
        }
        @include media-breakpoint-down(sm) {
            &.inner-wrapper-sm{
                flex-direction: column;
            }
        }
    }
    @include media-breakpoint-up(md) {
        padding-top: 16px;
        padding-bottom: 0;
    }
}
.popular-search {
    .popular-header {
        margin-bottom: 1rem;
        text-transform: uppercase;
        font-weight: 800;
    }
    .separator {
        padding: 4px;
    }
    .category-name {
	    color: $dark-gray;
    }
}
