@import '~core/product/grid';

.filter-bar {
    li {
        &.filter-value {
            button {
                &.reset {
                    padding: 7.5px 10px;
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

.horizontal-filter-refinements-bar {
    .horizontal-filter-refinement.card + .horizontal-filter-refinement.card {
        margin-left: 10px;
    }

    .product-tile-layout {
    	margin-left: 20px;
    	line-height: 44px;
    	border: 1px solid;
    	.grid {
		    padding: 10px;
		    line-height: 22px;
		    cursor: pointer;
    		&.active {
    			font-weight: bold;
    			background: #000;
		    	color: white;
    		}
    	}
        @media screen and (min-width: 1590px) {
            display: none !important;
        }
    }
}

.horizontal-multipledropdowns-filter-dropdown-menu {
    max-height: 50vh;
    overflow: auto;
}

.refinements {
    .values.content {
        .custom-category-label {
            padding-top: 10px;
            font-size: 13px;
            font-weight: bold;
            line-height: 18px;
        }
    }
}

.content-tracker-header {
     a {
        color: $brand-tertiary;
        text-decoration: underline;
    }
}

.content-tracker-footer {
    padding: 20px 0;

    a {
        color: $brand-tertiary;
        text-decoration: underline;
    }

    h2 {
        font-size: initial;
        text-transform: initial;
    }
}

.faq-accordion {
    margin-top: 20px;
    border-bottom: 1px solid #C5C5C5;
    .title {
	    margin-bottom: 10px;
	    font-size: 16px;
	    font-weight: bold;
	}

    .card-header {
    	position: relative;
    }

    .card-header, .card-body {
        background-color: transparent;
	    border-bottom: none;
	    padding: 0 0 20px 0;
    }

    .card-link {
        cursor: pointer;
        position: relative;
        &:after {
			content: "\E92C";
		    font-family: "icomoon";
		    display: inline-block;
		    font-size: 12px;
		    color: #222222;
		    font-weight: normal;
		    position: absolute;
		    right: 0;
		    top: 50%;
		    transform: translate(0px, -50%);
		}
	}

	.card-link.collapsed:after {
		content: "\E931";
	}

	.question {
		margin-bottom: 5px;
	}

	.answer {
		margin-bottom: 20px;
	}
}
