@import "~core/cart/cart";

.cart-page {
    .bundled-line-item {
        &:not(:empty) {
            border-top: 1px solid $card-body-border-color;
            border-left: none;
            border-right: none;
            border-bottom: none;
            padding: $spacer 0px;
        }
        &~.bundled-line-item {
            border-top: 1px solid $card-body-border-color;
        }
    }
    // mobile footer
    .checkout-continue {
        @include media-breakpoint-down(xs) {
            bottom: 60px;
        }
    }

}

.custom-promo {
    display: flex;
    position: relative;
    bottom: 92px;
    flex-direction: column;
    min-height: 100px;
    @include media-breakpoint-up(lg) {
        bottom: 52px;
    }
    @media only screen and (min-device-width : 820px) and (max-device-width : 1180px)  {
        bottom: 69px;
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
        bottom: 72px;
    }
}

.consentopen .cart-page .checkout-continue{
    @include media-breakpoint-down(xs) {
        bottom: 132px;
    }
}
