@import '~core/checkout/checkout';

.consentopen .data-checkout-stage .next-step-button{
    @include media-breakpoint-down(sm) {
        bottom: 77px;
    }
}

.data-checkout-stage {
    .next-step-button{
        @include media-breakpoint-down(sm) {
            padding-bottom: 60px;
            z-index: calc($z-index-sticky-footer - 1);
        }
    }
}

.customer-error {
    padding: 0 10px;
    border: 1px solid $card-header-border-color;
    border-bottom: none;
    border-top: none;
}
