@import "~core/components/productTiles";

.product-tile {
    position: relative;

    .product-tile-image-hover {
        position: absolute;
    }
    .quickview {
        &.btn {
            border: none;
        }
    }

    .pdp-link {
        .link {
            text-transform: uppercase;
            .brand {
                display: block;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: $product-tile-font-letter-spacing;
            }
            .name{
                letter-spacing: $product-tile-font-letter-spacing;
            }
        }
    }
    &:hover {
        .product-tile-image-container:after {
            box-shadow: none;
        }
    }
}
