@import "~core/components/common";

html {
    font-family: $default-text-font;
}

.page {
    background-color: $white;
}

.pdp {
    background-color: #f7f7f7;
}

.product-detail .product-shipping a, .blog.page-designer .experience-main p a {
    color: $yellow;
    text-decoration: underline;
}

picture img {
    width: 100%;
}
