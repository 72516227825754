@import "~core/components/modal";

.modal {
    .modal-header {
        position: relative;
        justify-content: center;
    }

    .close {
        position: absolute;
        right: 20px;
    }
}
